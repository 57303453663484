import { BehaviorSubject, Observable } from 'rxjs';

export class InternetStatus {
  private static internetStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.navigator.onLine);
  public static internetStatus$: Observable<boolean> = InternetStatus.internetStatusSubject.asObservable();

  static initializeInternetStatusTracking(): void {
    window.addEventListener('online', () => {
      InternetStatus.updateInternetStatus(true);
    });

    window.addEventListener('offline', () => {
      InternetStatus.updateInternetStatus(false);
    });
  }

  private static updateInternetStatus(isOnline: boolean): void {
    InternetStatus.internetStatusSubject.next(isOnline);
  }
}
