import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './user.service';
import { PopupService } from './popup.service';
import { MapsService } from './maps.service';
import { StreamService } from './core/stream.service';
import { CmsService } from './cms.service';
import { PlanService } from './plan/plan.service';
import { CookiesService } from './helpers/cookies.service';
import { LocalStorageService } from './helpers/local-storage.service';
import { FacadeService } from './core/facade.service';
import { LoggerService } from './log4ts/logger.service';
import { ConsoleLoggerService } from './log4ts/console-logger.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpsRequestInterceptor } from './core/http.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from './error-handler/message.service';
import { HttpErrorHandler } from './error-handler/http-error-handler.service';
import { AwsService } from './core/aws.service';
import { FacilitiesService } from './facilities.service';
import { CanonicalService } from './helpers/canonical.service';
import { ZendeskService } from './helpers/zendesk.service';
import { YextService } from './yext.service';
import { ScreenAndDeviceService } from './helpers/screen-and-device.service';
import { LandingService } from './landing.service';
import { FundingPlanService } from './plan/funding-plan.service';
import { WcagService } from './helpers/wcag.service';
import { AdyenPaymentService } from './adyen-service/adyen-payment.service';
import { InternetStatusService } from '@core/services/helpers/internet-status.service';
import { PlanPricingService } from './plan-pricing.service';


@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    CookieService,
    AwsService,
    UserService,
    PopupService,
    MapsService,
    StreamService,
    CmsService,
    PlanService,
    CookiesService,
    LocalStorageService,
    MessageService,
    FacilitiesService,
    HttpErrorHandler,
    { provide: LoggerService, useClass: ConsoleLoggerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    FacadeService,
    CanonicalService,
    ZendeskService,
    YextService,
    ScreenAndDeviceService,
    LandingService,
    FundingPlanService,
    WcagService,
    AdyenPaymentService,
    PlanPricingService,
    InternetStatusService
  ],
})
export class ServicesModule {}
