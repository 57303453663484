import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { IPaymentConfiguration } from '@public-pages/pages/checkout/checkout/checkout-form-v2/checkout-form-v2.types';

@Injectable()
export class AdyenPaymentService {
  constructor(private httpClient: HttpClient) {}

  getConfig(facilityId: string): Promise<IPaymentConfiguration> {
    return this.httpClient
      .get<any>(`${environment.API_CONF.JOIN_SERVICE_CONFIG_API}${facilityId}/config`)
      .toPromise();
  }

}
