import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { IValidPrepaidParams, IValidPrepaidResponse } from '@models';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  /**
 * @constructor
 * @param {HttpClient} httpClient
 */
  constructor(
    private httpClient: HttpClient
  ) {}

  validatePrepaidCard(params: IValidPrepaidParams): Observable<IValidPrepaidResponse> {
    const url = `${environment.API_CONF.CARDS_API}/cards/prepaid/validate`;
    return this.httpClient.post<IValidPrepaidResponse>(url, params);
  }
}

