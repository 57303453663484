import {Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {TermsData} from '@shared/components/terms-content/terms-content.types';
import {ITermsClick} from './terms-checkbox.types';
import { WcagService } from '@core/services/helpers/wcag.service';

declare let window: Window;

@Component({
  selector: 'bw-terms-checkbox',
  templateUrl: './terms-checkbox.component.html',
  styleUrls: ['./terms-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsCheckboxComponent implements OnInit, AfterViewInit {
  @ViewChild('rtfLabelEle') rtfLabelEle: ElementRef;
  @Output() onTermClick = new EventEmitter<ITermsClick>();
  @Output() onChange = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter();
  @Input() name = 'checkboxTerms';
  @Input() id = 'checkbox-terms';
  @Input() preFix = 'Yes, I agree to the ';
  @Input() posFix = ', which includes receiving email communication from Blink';
  @Input() errorMessage = 'Please agree to the terms of use and privacy policy to proceed to the next step';
  @Input() requiredRead = false;
  @Input() requiredReadError = 'Please read the terms to continue';
  @Input() linkColor = '#2c2c2c';
  @Input() fontSize = '14px';
  @Input() group: UntypedFormGroup;
  @Input() list: TermsData[];
  @Input() active: number;
  @Input() rtfLabel: string;
  @Input() linkRules: string;
  clickedList: boolean[];
  window: Window = window; // This is necessary for unity test
  ariaLabel: string;
  ariaLabelTimeout: NodeJS.Timeout;

  ARIA_LABEL_DELAY_MILLI = 700
  inputId: string;

  constructor(private _wcagService: WcagService) {}
  /**
   * OnInit Lifecycle
   */
  ngOnInit() {
    this.clickedList = this.list.map(() => false);
    if (this.requiredRead) {
      this.group.controls[this.name]?.setValidators([
        Validators.requiredTrue,
        this.validateRequiredRead.bind(this),
      ]);
    }
    this.inputId = this.id + `-input`;
  }

  ngAfterViewInit() {
    if (this.rtfLabel) {
      let idx = 0;
      const arrHTMLElements = Array.from(this.rtfLabelEle.nativeElement.children[0].children);
      arrHTMLElements.forEach((element: HTMLElement) => {
        if (!element.dataset.nolink && element.tagName.toLowerCase() === 'a'){
          element.setAttribute('style', `color: ${this.linkColor}; border-bottom-color: ${this.linkColor}; cursor:pointer;`);
          element.addEventListener('click', this.onClick.bind(this, idx));
          element.classList.add('terms-link');
          element.dataset.index = idx.toString();
          idx++;
        }
        if (element.tagName.toLowerCase() !== 'a') {
          element.setAttribute('aria-hidden', 'true');
        }
      });
    }

    this.buildLabel();

  }

  /**
   * Validates if checkbox has required to read terms
   */
  validateRequiredRead() {
    if (!this.requiredRead) {
      return null;
    }

    return this.clickedList.some(value => !value) ? {TermValidator: {value: true}} : null;
  }

  /**
   * Checkbox on change
   * @param {MatCheckboxChange} event
   * @callback checkbox onChange
   */
  onCheckboxChange(event: MatCheckboxChange): void {
    this.onChange.emit(event.checked);
  }

  /**
   * On term Click event
   * @param {number} index
   */
  onClick(index: number): void {
    if (index > (this.list.length - 1)) {
      this.window.open(this.linkRules);
      return;
    }

    const list = this.list.length ? this.list[index] : {};
    const {externalLink} = list;

    this.clickedList[index] = true;
    this.group.controls[this.name]?.updateValueAndValidity();

    if (externalLink) {
      this.window.open(externalLink);
      return;
    }

    this.onTermClick.emit({index});
  }

  buildLabel(): void{
    if (this.rtfLabel) {
      const nativeElement = this.rtfLabelEle.nativeElement;
      this.ariaLabel = (nativeElement?.innerText || nativeElement?.textContent)?.trim().replace(/(\r\n|\r|\n)/g, ' ').replace(/\s+/g, ' ');
    } else {
      this.ariaLabel = '';
      if (this.preFix){
        this.ariaLabel = this.ariaLabel + this.preFix;
      }

      this.list?.forEach((term, idx) => {

        if ((idx + 1) == this.list.length){
          if (this.list.length > 2){
            this.ariaLabel = this.ariaLabel + ', and';
          }else if (this.list.length > 1){
            this.ariaLabel = this.ariaLabel + ' and';
          }
        }

        this.ariaLabel = this.ariaLabel + ' ' + term.text;
      });

      this.ariaLabel = this.ariaLabel + this.posFix;
    }
    this._wcagService.addAriaLabelToCheckboxInput(this.inputId, this.ariaLabel, this.ariaLabelTimeout, this.ARIA_LABEL_DELAY_MILLI);
  }

  onFocusOut(): void{
    this.group.get(this.name).markAsDirty();
  }
}
