import { Injectable } from '@angular/core';
import {InternetStatus} from '../../../shared/internet-status/internet-status';


@Injectable({
  providedIn: 'root'
})
export class InternetStatusService {
  public internetStatus$ = InternetStatus.internetStatus$;

  constructor() {
    InternetStatus.initializeInternetStatusTracking();
  }
}
